/* You can add global styles to this file, and also import other style files */
@import "~@angular/material/prebuilt-themes/indigo-pink.css";

.delete-icon-btn {
    border: none;
    background: none;
    padding: 0;
}

ngx-datatable .empty-row {
    text-align: center !important;

}

.ele-disabled {
    cursor: not-allowed;
    pointer-events: none;
    color: #b1b1b1 !important;
}

.datatable-row-center.datatable-row-group {
    margin-bottom: 10px;
}

.cdk-overlay-pane {
    background: #ffff !important;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%) !important;
}

.cdk-overlay-container .mat-dialog-container {
    padding: 0;
}

.btn-action-icon {
    border: 1px solid;
    border-radius: 4px;
    padding: 2px;
    line-height: 1;
    display: inline-block;
    background-color: transparent;
}
  
.btn-action-icon .text {
    font-size: 10px;
    line-height: 0.5;
    text-transform: uppercase;
}